<template>
    <v-card
        color="transparent"
        outlined
    >
        <v-card-title>{{ title }}</v-card-title>
        <v-divider />
        <v-data-table
            @click:row="preview"
            :items="items"
            :headers="headers"
            :loading="loading"
            :items-per-page="-1"
            loading-text="Laddar..."
            no-data-text="0 resultat"
            hide-default-footer
            disable-sort
        >
            <template #item._created="{ item }">
                {{ format_date_time(item['_created']) }}
            </template>
            <template #item.pdf>
                <v-icon
                    color="red"
                    large
                >
                    mdi-file-pdf-box
                </v-icon>
            </template>
        </v-data-table>
        <PreviewDialog
            v-if="preview_content"
            @close="preview_content = false"
            :data="internal_value"
            tool="cv"
        />
    </v-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { api_mixin } from '@/mixins/services/api_mixin'
import { date_mixin } from '@/mixins/services/date_mixin'
import PreviewDialog from '@/components/user/PreviewDialog.vue'

export default {
    name: 'Tools',
    props: {
        tool: {
            type: String,
        },
        user_id: {
            type: String,
        },
    },
    mixins: [api_mixin, date_mixin],
    components: { PreviewDialog },
    data() {
        return {
            items: [],
            loading: true,
            preview_content: false,
            internal_value: {},
        }
    },
    computed: {
        title() {
            if (this.tool === 'cv') {
                return 'CV'
            } else {
                return 'Jobbansökan'
            }
        },
        headers() {
            return [
                {
                    text: 'Skapad',
                    value: '_created',
                },
                {
                    text: 'Namn',
                    value: this.tool === 'cv' ? 'name' : 'application_name',
                },
                {
                    text: 'Filtyp',
                    align: 'center',
                    value: 'pdf',
                },
            ]
        },
        ...mapGetters([]),
        ...mapState([]),
    },
    watch: {},
    methods: {
        async load_data() {
            const url = this.tool === 'cv' ? 'x-u2work--cv' : 'x-u2work--job-applications'
            const result = await this.api_get_all_items(url, '_created', { user: this.user_id })
            this.items.push(...result)
            this.loading = false
        },
        preview(item) {
            this.internal_value = item
            this.preview_content = true
        },
        ...mapActions([]),
    },
    beforeCreate() {},
    created() {},
    beforeMount() {},
    mounted() {
        this.load_data()
    },
    beforeUpdate() {},
    updated() {},
    beforeDestroy() {},
    destroyed() {},
}
</script>

<style scoped lang="sass"></style>
